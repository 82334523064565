<template lang="pug">
  .button-temp(:class="butClassMerger"
            @click="handleClick") {{ title }}
</template>

<script>
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    funcToBeCalled: {
        type: String,
        default: '',
    },
    isActive: {
        type: Boolean,
        default: false,
    },
    buttonShape: {
        type: String,
        default: 'normal',
    },
    buttonSize: {
        type: String,
        default: 'normal',
    },
    buttonStyle: {
        type: String,
        default: '',
    },
    isBtnDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () =>({
    isClicked: false,
  }),
  computed: {
    butClassMerger() {
      return {
          'active': this.isActive,
          'border-off': this.buttonShape === 'borderOff',
          'gradient': this.buttonShape === 'gradient',
          'gradient-off': this.buttonShape === 'gradientOff',
          'selected': this.isClicked,
          'sm': this.buttonSize == 'small',
          'pg-btn': this.buttonStyle == 'personalGame',
          'disabled': this.isBtnDisabled
      };
    }
  },
  methods: {
    handleClick() {
      this.isClicked = true;
      this.$emit('emitButton', this.funcToBeCalled);
        setTimeout(() => {
          this.isClicked = false;
        }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/serviceComponents/button_sw.scss';
</style>
